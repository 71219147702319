<template>
  <!-- Navbar -->
  <nav class="bg-transparent border-gray-200 dark:bg-transparent flex justify-between items-center fixed w-full z-50">
    <!-- Language Toggle Button -->
    <button
      @click="toggleLanguage"
      class="ml-auto inline-flex dark:bg-transparent items-center p-1 text-sm text-black rounded-lg hover:bg-gray-100 dark:text-black dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    >
      {{ language === 'en' ? 'മലയാളം' : 'English' }}
    </button>
  </nav>

  <!-- Floating WhatsApp Button -->
  <a
    href="https://wa.me/9447357046"
    target="_blank"
    class="fixed bottom-24 right-8 z-50"
  >
    <button class="w-14 h-14 bg-green-500 rounded-full flex items-center justify-center shadow-lg hover:bg-green-600 transition-colors">
      <ion-icon name="logo-whatsapp" class="text-white text-3xl"></ion-icon>
    </button>
  </a>

  <!-- Floating Call Button -->
  <a href="tel:+919447357046" class="fixed bottom-8 right-8 z-50">
    <button class="w-14 h-14 bg-green-500 rounded-full flex items-center justify-center shadow-lg hover:bg-green-600 transition-colors">
      <ion-icon name="call" class="text-white text-2xl"></ion-icon>
    </button>
  </a>

  <!-- First Slide with Background Image and Text -->
  <div class="min-h-screen bg-cover bg-center" :style="{ backgroundImage: `url(${require('@/assets/5.jpg')})` }">
    <div class="absolute inset-0 bg-black bg-opacity-0 flex flex-col items-center justify-center">
      <div class="text-center text-white">
        <h1 class="text-5xl font-bold mb-4">{{ language === 'en' ? 'സൂപ്പർ സ്മോക്ക്ലെസ് ഓവനിലേക്ക് സ്വാഗതം': 'Welcome to Super Smokeless Oven' }}</h1>
        <h2 class="text-2xl mb-2">{{ language === 'en' ? 'പരിചയസമ്പന്നരായ തൊഴിലാളികൾ രൂപകല്പന ചെയ്ത ബജറ്റിന് അനുയോജ്യമായ പുകയില്ലാത്ത ആലുവ ഓവൻ' : 'Budget-Friendly Smokeless Aluva oven crafted by experienced workers' }}</h2>
        <h3 class="text-2xl font-bold mb-8">{{ language === 'en' ? 'മൊത്തവ്യാപാരവും ചില്ലറവ്യാപാരവും' : 'Wholesale & Retail' }}</h3>
        <!-- Call Now Button -->
        <a href="tel:+919447357046" class="bg-white bg-opacity-20 text-white px-8 py-3 rounded-lg font-semibold border-2 border-white hover:bg-opacity-30 transition duration-300">
          {{ language === 'en' ? 'ഇപ്പോൾ വിളിക്കുക': 'Call Now' }}
        </a>
      </div>
    </div>
  </div>

  <!-- About Us Section -->
  <div class="bg-gray-100 py-12">
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {{ language === 'en' ? 'ഞങ്ങളേക്കുറിച്ച്' : 'ABOUT US' }}
        </h2>
        <p class="mt-4 text-xl text-gray-600">
          {{ language === 'en' ? 'സൂപ്പർ പുകയില്ലാത്ത ഓവൻ' : 'Super Smokeless Oven' }}
        </p>
      </div>

      <div class="mt-10">
        <p class="text-lg text-gray-700">
          {{ language === 'en' ? '2005-ൽ എം.ടി.അബ്ദുൾ ഖാദറിൻ്റെ നേതൃത്വത്തിലാണ് സൂപ്പർ സ്മോക്ക്ലെസ് ഓവൻ ആലുവ അടുപ്പുകൾ സ്ഥാപിച്ചത്. ഞങ്ങൾ വീടുകളിൽ നിന്ന് ഹോട്ടലുകൾ, കാൻ്റീനുകൾ, റിസോർട്ടുകൾ എന്നിവയും അതിലേറെയും പോലുള്ള വലിയ തോതിലുള്ള ബിസിനസ്സുകളിലേക്ക് സേവനങ്ങൾ നൽകുന്നു. വയനാട്ടിലെ ആദ്യത്തെ ആലുവ അടുപ്പ് കടകളിൽ ഒന്നാണ് ഞങ്ങളുടേത്.' : 'Super Smokeless Oven Aluva Aduppukal was established in the year 2005 under the leadership of MT Abdul Khadar. We provide services from Houses to large-scale businesses such as Hotels, Canteens, Resorts and more. We are one of the first Aluva Adupp shops in Wayanad.' }}
        </p>
        <p class="mt-6 text-lg text-gray-700">
          {{ language === 'en' ? 'പരിചയസമ്പന്നരായ വിദഗ്ധ തൊഴിലാളികൾ ഉള്ളതിനാൽ, കാസ്റ്റ്-ഇരുമ്പ്, പീസ് സ്റ്റീൽ, ഫുൾ സ്റ്റീൽ, ബോക്സ് തരം എന്നിങ്ങനെ വ്യത്യസ്ത മോഡലുകൾ ഞങ്ങൾ ബജറ്റിന് അനുയോജ്യമായ വിലയിൽ മികച്ച നിലവാരത്തിൽ നിർമ്മിക്കുന്നു.' : 'By having experienced skilled workers, we manufacture different models of oven such as cast-iron, piece steel, full steel, and box type at Budget-Friendly price with excellent quality.' }}
        </p>
      </div>
    </div>
    <br><br>
  </div>

<!-- What We Offer Section -->
<div class="bg-gray-200 py-12 rounded-lg">
  <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl text-center">
    {{ language === 'en' ? 'ഞങ്ങൾ എന്താണ് വാഗ്ദാനം ചെയ്യുന്നത്' : 'What we offer' }}
  </h2>
  <br><br>

  <!-- Carousel -->
  <div id="default-carousel" class="relative w-full max-w-4xl mx-auto" data-carousel="slide">
    <!-- Carousel wrapper -->
    <div class="relative h-[400px] overflow-hidden rounded-lg">
      <!-- Item 1 -->
      <div class="hidden duration-700 ease-in-out" data-carousel-item>
        <img src="@/assets/1.jpg" class="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Slide 1">
      </div>
      <!-- Item 2 -->
      <div class="hidden duration-700 ease-in-out" data-carousel-item>
        <img src="@/assets/2.jpg" class="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Slide 2">
      </div>
      <!-- Item 3 -->
      <div class="hidden duration-700 ease-in-out" data-carousel-item>
        <img src="@/assets/3.jpg" class="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Slide 3">
      </div>
      <!-- Item 4 -->
      <div class="hidden duration-700 ease-in-out" data-carousel-item>
        <img src="@/assets/4.jpg" class="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Slide 4">
      </div>
      <!-- Item 5 -->
      <div class="hidden duration-700 ease-in-out" data-carousel-item>
        <img src="@/assets/6.jpg" class="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Slide 5">
      </div>
    </div>

    <!-- Slider indicators -->
    <div class="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
      <button type="button" class="w-3 h-3 rounded-full bg-white/50 hover:bg-white/80" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
      <button type="button" class="w-3 h-3 rounded-full bg-white/50 hover:bg-white/80" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
      <button type="button" class="w-3 h-3 rounded-full bg-white/50 hover:bg-white/80" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
      <button type="button" class="w-3 h-3 rounded-full bg-white/50 hover:bg-white/80" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
      <button type="button" class="w-3 h-3 rounded-full bg-white/50 hover:bg-white/80" aria-current="false" aria-label="Slide 5" data-carousel-slide-to="4"></button>
    </div>

    <!-- Slider controls -->
    <button type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
      <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
        <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
        </svg>
        <span class="sr-only">Previous</span>
      </span>
    </button>
    <button type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
      <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
        <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="sr-only">Next</span>
      </span>
    </button>
  </div>
  <br><br><br>
    <div class="bg-gray-100 flex justify-center items-center">
    <div class="w-full md:w-3/4 lg:w-3/5 px-4 sm:px-10 flex flex-col">
      <!-- Section Heading -->
       <br><br><br>
      <h2 class="text-2xl sm:text-3xl font-extrabold text-gray-900 text-center">  
        {{  language === 'en' ? 'ഞങ്ങളുടെ ഉപഭോക്താവിന് പറയാനുള്ളത്' : 'What Our Customer has to say' }}
      </h2>
        
      <!-- Item Container -->
       <br>
      <div class="flex flex-col gap-3 mt-2 sm:mt-1">
        <!-- Review Card 1 -->
        <div class="flex flex-col gap-4 bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <!-- Profile and Rating -->
          <div class="flex justify-between flex-col sm:flex-row gap-2 sm:gap-0">
            <div class="flex gap-2 items-center">
              <div class="w-7 h-7 text-center rounded-full bg-red-500 text-white flex items-center justify-center">Z</div>
              <span class="text-gray-900">
                {{ language === 'en' ? 'സൈനുൽ ആബിദ്' : 'Zainul Abid' }}
              </span>
            </div>
            <div class="flex p-1 gap-1 text-orange-300">
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
            </div>
          </div>

          <!-- Review Text -->
          <div class="text-gray-700 text-sm sm:text-base">
            {{ language === 'en' ? 'ഷോപ്പ് ഉടമയുടെ മികച്ച ഗുണനിലവാരവും സത്യസന്ധതയും. നിങ്ങൾ ഏതെങ്കിലും തരത്തിലുള്ള മികച്ച പുകയില്ലാത്ത ഓവനിനായി തിരയുകയാണെങ്കിൽ ഈ ഷോപ്പ് ഞാൻ വളരെ ശുപാർശ ചെയ്യുന്നു, പരിശോധിക്കാനുള്ള ശരിയായ സ്ഥലമാണിത്.' : 'Great Quality and Honesty by shop owner. I highly recommend this shop if you are looking for top-notch smokeless oven of any type, this is the right spot to check out.' }}
          </div>
        </div>

        <!-- Review Card 2 -->
        <div class="flex flex-col gap-4 bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <!-- Profile and Rating -->
          <div class="flex justify-between flex-col sm:flex-row gap-2 sm:gap-0">
            <div class="flex gap-2 items-center">
              <div class="w-7 h-7 text-center rounded-full bg-yellow-500 text-white flex items-center justify-center">A</div>
              <span class="text-gray-900">
                {{ language === 'en' ? 'നടൻ അബു സലിം' : 'Actor Abu Salim' }}
              </span>
            </div>
            <div class="flex p-1 gap-1 text-orange-300">
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
            </div>
          </div>

          <!-- Review Text -->
          <div class="text-gray-700 text-sm sm:text-base">
            {{ language === 'en' ? 'മികച്ച ജോലി.' : 'Great Work.' }}
          </div>
        </div>
        <!-- Review Card 3 -->
          <div class="flex flex-col gap-4 bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <!-- Profile and Rating -->
          <div class="flex justify-between flex-col sm:flex-row gap-2 sm:gap-0">
            <div class="flex gap-2 items-center">
              <div class="w-7 h-7 text-center rounded-full bg-red-500 text-white flex items-center justify-center">B</div>
              <span class="text-gray-900">
                {{ language === 'en' ? 'ശശി' : 'Sasi' }}
              </span>
            </div>
            <div class="flex p-1 gap-1 text-orange-300">
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
            </div>
          </div>

          <!-- Review Text -->
          <div class="text-gray-700 text-sm sm:text-base">
            {{ language === 'en' ? 'ഈ പ്രദേശത്ത് മികച്ച പുകയില്ലാത്ത ഓവൻ വിതരണം ഈ ഷോപ്പ് വാഗ്ദാനം ചെയ്യുന്നു. മാനേജ്മെൻ്റ് മികച്ചതാണ്, കൂടാതെ സ്റ്റാഫ് കുറ്റമറ്റ സേവനവും നൽകുന്നു.' : 'This shop offers excellent smokeless oven distribution in this area. The management is top-notch, and the staff delivers flawless service as well.' }}
          </div>
        </div>
          <!-- Review Card 4 -->
          <div class="flex flex-col gap-4 bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <!-- Profile and Rating -->
          <div class="flex justify-between flex-col sm:flex-row gap-2 sm:gap-0">
            <div class="flex gap-2 items-center">
              <div class="w-7 h-7 text-center rounded-full bg-yellow-500 text-white flex items-center justify-center">M</div>
              <span class="text-gray-900">
                {{ language === 'en' ? 'മോഹനൻ' : 'Mohanan' }}
              </span>
            </div>
            <div class="flex p-1 gap-1 text-orange-300">
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
            </div>
          </div>

          <!-- Review Text -->
          <div class="text-gray-700 text-sm sm:text-base">
            {{ language === 'en' ? 'സൗഹൃദപരവും മികച്ചതുമായ സേവനത്തോടൊപ്പം അവർ ഉയർന്ന നിലവാരമുള്ള ഉൽപ്പന്നങ്ങൾ നൽകുന്നു.' : 'They deliver high-quality products along with friendly and excellent service.' }}
          </div>
        </div>
          <!-- Review Card 5 -->
          <div class="flex flex-col gap-4 bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <!-- Profile and Rating -->
          <div class="flex justify-between flex-col sm:flex-row gap-2 sm:gap-0">
            <div class="flex gap-2 items-center">
              <div class="w-7 h-7 text-center rounded-full bg-yellow-500 text-white flex items-center justify-center">S</div>
              <span class="text-gray-900">
                {{ language === 'en' ? 'സെബാസ്റ്റ്യൻ' : 'Sebastian' }}
              </span>
            </div>
            <div class="flex p-1 gap-1 text-orange-300">
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
              <ion-icon name="star"></ion-icon>
            </div>
          </div>

          <!-- Review Text -->
          <div class="text-gray-700 text-sm sm:text-base">
            {{ language === 'en' ? 'ന്യായമായ വിലയിൽ മികച്ച ജോലി.' : 'Excellent work at reasonable price.' }}
          </div>
        </div>
        <br><br>
      </div>
    </div>
</div>
<br><br>
<h2 class="text-2xl sm:text-3xl font-extrabold text-gray-900 text-center">  
        {{ language === 'en' ? 'ഞങ്ങളുടെ ലൊക്കേഷൻ': 'Our Location' }} 
      </h2>
<div style="display: flex; justify-content: center; align-items: center; min-height: 60vh;">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4478.006641313465!2d76.08125667562648!3d11.617751988586827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba673c76e336d23%3A0xdb660d0fddc94dfc!2z4LSG4LSy4LWB4LS1IOC0quC1geC0leC0r-C0v-C0suC1jeC0suC0vuC0pOC1jeC0pCDgtIXgtJ_gtYHgtKrgtY3gtKrgtYHgtJXgtb4!5e1!3m2!1sen!2sin!4v1735735346041!5m2!1sen!2sin"
    width="600"
    height="450"
    style="border:0;"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
    class="rounded-lg mx-5"
  ></iframe>
  </div>
</div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      language: 'ml', // Default language is Malayalam
    };
  },
  methods: {
    toggleLanguage() {
      this.language = this.language === 'ml' ? 'en' : 'ml'; // Toggle between English and Malayalam
    },
  },
};

</script>

<style scoped>
/* Add custom styles here if needed */
</style>